import React from "react";
import WhatsappIcon from "../images/icono-whatsapp.svg";

const WhatsAppButton = ({ message }) => {
  const phoneNumber = "+56931896260"; // Replace with your WhatsApp number
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-button"
    >
      <img src={WhatsappIcon} alt="WhatsApp" className="whatsapp-ico" />
    </a>
  );
};

export default WhatsAppButton;
